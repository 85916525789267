/* App */

.anchor {
  padding-top: 80px;
}

.modal p {
  text-align: center;
}

/* Header */

.header_company_name {
  font-size: 26px;
  font-weight: 600;
}

.header_link_element {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 8px 2px;
  border-radius: 0;
}

.header_navbar {
  width: 100%;
}

/* .nav-link:not(:last-child) {
  border-right: 0.5px solid #000000;
} */

.header_overlay {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(72, 74, 73, 0.5) 100%
  );
}

.header_main_image {
  width: 100% !important;
}

.card-title {
  margin-top: 30%;
}

.header_overlay_title {
  margin-top: 20% !important;
  color: rgb(255, 255, 255);
  font-size: 64px;
  font-weight: 700;
  letter-spacing: -1px;
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
}

.header_overlay_description {
  color: #fff;
  font-size: 35px;
  margin-top: 20px;
  line-height: 28px;
  margin-bottom: 40px;
}

@media screen and (max-width: 600px) {
  .header_overlay_title {
    font-size: 45px;
    font-weight: 700;
  }

  .header_overlay_description {
    font-size: 16px;
  }
  .header_main_image {
    margin-top: 65px;
  }
}

@media screen and (max-width: 450px) {
  .header_overlay_title {
    font-size: 30px;
    font-weight: 700;
  }

  .header_overlay_description {
    font-size: 16px;
  }
  .header_main_image {
    margin-top: 65px;
  }
}

@media screen and (max-width: 370px) {
  .header_overlay_title {
    font-size: 25px;
    font-weight: 700;
  }

  .header_overlay_description {
    font-size: 14px;
    margin-top: 10px;
    line-height: 0px;
  }
  .header_company_name {
    font-size: 14px;
    font-weight: 600;
  }
  .header_main_image {
    margin-top: 55px;
  }
}

/* About */

.about span {
  color: #70ad47;
  font-weight: 700;
  letter-spacing: -1px;
}

.about hr {
  height: 3px !important;
  width: 25%;
  text-align: center;
  position: relative;
  background: #6aaf08;
  margin: 0;
  margin-bottom: 20px;
  border: 0;
}

.collage_image {
  width: 100%;
  margin-left: 15%;
}

.about p {
  text-align: justify;
}

@media screen and (max-width: 800px) {
  .about_description {
    display: block !important;
  }
  .collage_image {
    margin-left: 0%;
  }
}
/* Services */
.services {
  margin-top: 50px;
  background-color: #6aaf08;
}

.services p,
.services h2,
.services h3 {
  text-align: center;
}

.services img {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  
}

@media screen and (max-width: 1000px) {
  .services h3 {
    font-size: 15px !important;
  }
  .services p {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 800px) {
  .services img {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .services_row {
    display: block !important;
  }
}

/* Photo Gallery */

.photo_gallery h2 {
  text-align: center;
}

/* Video Gallery */

.video_gallery h2 {
  text-align: center;
}

/* .video_gallery {
  margin-top: 50px;
} */

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* Contact */

/* .contact {
  margin-top: 50px;
} */

.contact h3,
.contact h2,
p {
  text-align: center;
}

/* Footer */
.footer {
  margin-top: 50px;
  background-color: #3f3e3e;
}

.footer ul {
  list-style-type: none;
  display: inline-flex;
  margin-top: 10px;
}

.footer li {
  margin-left: 10px;
}

.footer p {
  color: ivory;
}
